
import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
import Loader from "@/components/Loader";
window.$ = window.jQuery = require("jquery");

export default {
    name: "PostulacionPage",
    data() {
        let ss = new MainService();
        return {
            msg: "PostulacionPage",
            ss: ss,
            ajax: {
                "url": ss.indexPostulacion(),
                headers: ss.getToken(),
            },
            columns: [
                { data: 'id', name: 'id', orderable: false, searchable: false, visible: false },
                { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N', orderable: false, searchable: false },
                { data: 'Convocatoria', name: 'ca.Convocatoria', title: 'Convocatoria' },
                { data: 'Asignatura', name: 'ca.Asignatura', title: 'Asignatura' },
                { data: 'Persona', name: 'per.Persona', title: 'Persona' },
                { data: 'Fecha', name: 'post.Fecha', title: 'Fecha' },
                { data: 'Puntaje', name: 'post.Puntaje', title: 'Puntaje' },
                { data: 'TM', name: 'post.TM', title: 'Nota Mérito' },
                { data: 'TC', name: 'post.TC', title: 'Nota Competencia' },
                { data: 'Observacion', name: 'post.Observacion', title: 'Observacion' },
                { data: 'Habilitado', name: 'post.Habilitado', title: 'Habilitado' },
                { data: 'Ganador', name: 'post.Ganador', title: 'Ganador' },
                {
                    data: 'action',
                    orderable: false,
                    title: 'Acciones',
                    searchable: false
                },
            ],

            postulacions: [],
            postulacion: {},

            convocatorias: [],
            convocatoria: {},

            asignaturas: [],
            asignatura: {},

            personas: [],
            persona: {},

            convocatoriaasignaturas:{},
            convocatoriaasignatura:{},

            isLoading: false,
            errorBag: {}
        };
    },
    methods: {
        newPostulacion() {
            this.postulacion = {};
            this.$refs['frm-postulacion'].show();
        },
        showPostulacion(id) {
            this.isLoading=true;
            this.ss.showPostulacion(id).then(
                (result) => {
                    let response = result.data;
                    this.postulacion = response.data;
                    this.$refs['view-postulacion'].show();
                    this.isLoading=false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        },
        editPostulacion() {
            this.$refs['frm-postulacion'].show();
            this.$refs['view-postulacion'].hide();
        },
        cancelPostulacion() {
            if (this.postulacion.id) {
                this.$refs['view-postulacion'].show();
            }
            this.$refs['frm-postulacion'].hide();
        },
        savePostulacion() {
            this.ss.storePostulacion(this.postulacion).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    console.log(response);
                    this.$refs['frm-postulacion'].hide();
                    this.$refs['datatable-postulacion'].reload();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        deletePostulacion() {
            this.$swal({
                title: "Estás seguro que deseas eliminar?",
                text: "Esta acción es irreversible!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then((willDelete) => {
                    if (willDelete) {
                        this.ss.destroyPostulacion(this.postulacion)
                            .then((result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )
                                this.$refs['view-postulacion'].hide();
                                this.$refs['datatable-Postulacion'].reload();
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },
        getConvocatoria() {
            this.ss.listConvocatoria().then(
                (result) => {
                    let response = result.data;
                    this.convocatorias = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getAsignatura() {
            this.ss.listAsignatura().then(
                (result) => {
                    let response = result.data;
                    this.asignaturas = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getPersona() {
            this.ss.listPersona().then(
                (result) => {
                    let response = result.data;
                    this.personas = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        draw() {
            window.$('.btn-datatable-Postulacion').on('click', (evt) => {
                const data = window.$(evt.target)[0].id;
                this.showPostulacion(data);
            });
        }
    },
    components: {
        dataTable,
        Loader
    },
    mounted() {
        var persona = JSON.parse(localStorage.getItem('persona'));
        if (!persona) {
          this.$router.push('/Login');
        }

        this.getConvocatoria();
        this.getAsignatura();
        this.getPersona();
    }
};
