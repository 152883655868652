import MainService from "@/services/MainService.js";
import Vue from 'vue'
import VueAxios from 'vue-axios'
import axios from 'axios';
import msal from 'vue-msal'

import dataTable from "@/components/Datatable";
window.$ = window.jQuery = require("jquery");

import MenuExterno from "@/components/MenuExterno";
import FooterExterno from "@/components/FooterExterno";

Vue.use(VueAxios, axios)

Vue.use(msal, {
	auth: {
		clientId: process.env.VUE_APP_MSAL_CLIENT_ID,
		tenantId: process.env.VUE_APP_MSAL_TENANT_ID,
		redirectUri: process.env.VUE_APP_MSAL_REDIRECT_URI
	}
});


export default {
	name: 'MainPage',
	data() {
		let ss = new MainService();
		return {
			ss: ss,

			loginRequest: {},
			errorBag: {},
			rutaApp:process.env.VUE_APP_MAIN_SERVICE,

			ajax: {
                "url": ss.materiasConvocatoriaVigente(),
                //headers: ss.getToken(),
            },
			columns: [
                { data: 'id', name: 'id', orderable: false, searchable: false, visible: false },
                //{ data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N', orderable: false, searchable: false },
                //{ data: 'Materia', name: 'Materia', title: 'Materia' },
                //{ data: 'Detalle', name: 'Detalle', title: 'Detalle' },
                //{ data: 'CargaH', name: 'CargaH', title: 'CargaH' },
                //{ data: 'Requerimientos', name: 'Requerimientos', title: 'Requerimientos' },
                //{ data: 'Observacion', name: 'Observacion', title: 'Observacion' },
                { data: 'Convocatoria', name: 'Convocatoria', title: 'Convocatoria' },
                { data: 'Asignatura', name: 'Asignatura', title: 'Asignatura' },
                {
                    data: 'action',
                    orderable: false,
                    title: 'Acciones',
                    searchable: false
                },
                /* {
                    data: 'postular',
                    orderable: false,
                    title: 'Postular',
                    searchable: false
                }, */
            ],

			auth:{},

            personas: [],
			persona:{},
			
			datosInfo:{},

			unidadacademicas: [],
            unidadacademica: {},

			convocatorias: [],
            convocatoria: {},

            convocatoriaasignaturas: [],
            convocatoriaasignatura: {},

            isLoading: false,
            isLoadingFile: false

		};

	},
    components: {
		dataTable,
		MenuExterno,
		FooterExterno,
    },
	props: {
		msg: String
	},
	methods: {

		showConvocatoria(id) {
            this.isLoading=true;
            this.ss.showConvocatoria(id).then(
                (result) => {
                    let response = result.data;
                    this.convocatoria = response.data;
                    this.$refs['view-convocatoria'].show();
                    this.isLoading=false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        },

        showConvocatoriaAsignatura(id) {
            this.isLoading=true;
            console.log("Convocatoria Asignatura");
            this.ss.showConvocatoriaAsignatura(id).then(
                (result) => {
                    let response = result.data;

                    console.log(response);

                    this.convocatoriaasignatura = response.data;
                    this.$refs['view-convocatoriaasignatura'].show();
                    this.isLoading=false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        },

		verificarDatosCompletos(){

			this.auth = JSON.parse(localStorage.getItem("persona"))

			if(this.auth == null || this.auth == ""){
				// this.$swal({
				// 	title: "Usted no Inició Sesion",
				// 	text: "Por favor logueate, Luego podras inscribirte",
				// 	icon: "warning",
				// 	buttons: true,
                //     dangerMode: true,});

                   /*  this.$swal({
                        title: "No iniciaste sesión.",
                        text: "¡Por favor primero logueate!",
                        icon: "warning",
                        buttons: true,
                        dangerMode: true,
                    }).then(function() {
                        //window.location = "Login";
                        this.$router.push("");
                        //console.log('Hola!');
                    });
					return; */

                    this.$swal.fire({
                        title: "No iniciaste sesión.",
                        text: "¡Por favor primero loguéate!",
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonText: "Loguearse",
                        cancelButtonText: "Cancelar",
                    })
                    .then(resultado => {
                        if (resultado.value) {
                            // Hicieron click en "Sí"
                            //console.log("*se elimina la venta*");
                            this.$refs['frm-login'].show();

                        } else {
                            // Dijeron que no
                            //console.log("*NO se elimina la venta*");
                        }
                    });
			}
            else{
                this.$router.push("PersonaDocumento");
            }

            //this.$router.push("PersonaDocumento");
		},

        newPersona() {
            this.persona = {};
            this.$refs['frm-persona'].show();
        },

        cancelPersona() {
            this.$refs['frm-persona'].hide();
        },

        loadFile(input) {
            this.isLoadingFile = true;
            input = event.target;
            if (input.files && input.files[0]) {
                var reader = new FileReader();
                reader.readAsDataURL(input.files[0]);
                var data = new FormData();
                data.append('File', input.files[0]);
                this.ss.uploadFile(data)
                    .then(result => {
                        if (result.data.success) {
                            this.$bvToast.toast(
                                result.data.msg,
                                {
                                    title: 'Correcto',
                                    variant: 'info',
                                    autoHideDelay: 5000
                                }
                            )
                            this.persona.Foto = result.data.data;
                        } else {
                            this.$bvToast.toast(
                                result.data.msg,
                                {
                                    title: 'Oops!',
                                    variant: 'danger',
                                    autoHideDelay: 5000
                                }
                            )
                        }
                        this.isLoadingFile = false;
                    })
                    .catch(error => {
                        console.log(error);
                        this.$bvToast.toast(
                            'Error subiendo archivo',
                            {
                                title: 'Oops!',
                                variant: 'danger',
                                autoHideDelay: 5000
                            }
                        )
                        this.isLoadingFile = false;
                    });
            }
        },

        savePersona() {
            this.ss.personaStore(this.persona).then(
                (result) => {
                    //console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                   
                    this.$refs['frm-persona'].hide();
                    //this.$refs['datatable-persona'].reload();
                    
                    this.persona = response.data;
                    console.log(this.persona);

                    //let id = this.persona.id;
                    //console.log("id",id);

                   this.confirmacionPersona();
                }
                )
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    console.log(error);
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },

        confirmacionPersona() {
            
            //id = this.persona.id;
            console.log("enviar confirmacion al usuario!", this.persona.id);

            this.ss.confirmacionPersona({"id":this.persona.id}).then(
                (result) => {
                    console.log("result",result);
                    let response = result.data;

                    console.log("response",response);

                    this.$swal(
                    {
                                    title: "Correcto",
                                    text: "¡SE ENVIARON SUS CREDENCIALES DE ACCESO A SU CORREO!",
                                    icon: "success",
                                    buttons: true,
                                    dangerMode: true,
                    });
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    console.log(response);
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al enviar correo',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        })
                });
        },

		getUnidadAcademica() {
            this.ss.listUnidadAcademica().then(
                (result) => {
                    let response = result.data;
                    this.unidadacademicas = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },

        login() {
			this.ss.login(this.loginRequest)
				.then(
					(result) => {
						console.log(result);
						localStorage.setItem('persona', JSON.stringify(result.data.data));
						localStorage.setItem('token', JSON.stringify(result.data.token));
						this.$router.push('/');
						this.$router.go();
						/*var persona = JSON.parse(localStorage.getItem('persona'));
						console.log(persona)*/
					}
				)
				.catch((error) => {
					this.errorBag = error.response.data.errors;
					this.$bvToast.toast(
						'Problema al Iniciar Sesion',
						{
							title: 'Error',
							variant: 'danger',
							autoHideDelay: 5000
						}
					)
				});
		},

        authenticate() {
			if (!this.$msal.isAuthenticated()) {
				this.$msal.signIn();
			}
		},

		draw() {
             window.$('.btn-datatable-materiasConvocatoriaVigente').on('click', (evt) => {
                 const data2 = window.$(evt.target)[0];
                 
                 const data = window.$(evt.target)[0].id;

                 console.log("convocatoria2",data2);

                 console.log("convocatoria",data);
                 this.showConvocatoriaAsignatura(data); 
             });

            /*  window.$('.btn-datatable-mConvocatoria').on('click', (evt) => {
                const data = window.$(evt.target)[0].id;
                console.log(data);
                //this.showConvocatoriaAsignatura(data); 
            }); */
        },

	},
	mounted() {

		
        if(this.persona == null ){
            this.persona = {};
        }

        //this.persona = JSON.parse(localStorage.getItem("persona"));

		this.auth = JSON.parse(localStorage.getItem("persona"));


		window.scrollTo(0, 0);
		//this.getProgramasPost();
		if (!this.auth) {
            // this.$refs['info'].show();
        } 

		this.getUnidadAcademica();
		
		
	}
}