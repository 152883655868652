import MainService from "@/services/MainService.js";
import Vue from 'vue'
import VueAxios from 'vue-axios'
import axios from 'axios';
import msal from 'vue-msal'

import MenuExterno from "@/components/MenuExterno";
import FooterExterno from "@/components/FooterExterno";

Vue.use(VueAxios, axios)

Vue.use(msal, {
	auth: {
		clientId: process.env.VUE_APP_MSAL_CLIENT_ID,
		tenantId: process.env.VUE_APP_MSAL_TENANT_ID,
		redirectUri: process.env.VUE_APP_MSAL_REDIRECT_URI
	}
});


export default {
	name: 'InscribetePage',
	data() {
		let ss = new MainService();
		return {
			ss: ss,
			loginRequest: {},
			errorBag: {},
			programasPost:{},
			slide: 0,
			sliding: null,
			rutaApp:process.env.VUE_APP_MAIN_SERVICE,
			UAescogida:0,
			UAsagaEscogida:0,
			MostrarFiltrosTipoPro:0,
			auth:{},
			datosInfo:{}
		};

	},
    components: {
		MenuExterno,
		FooterExterno,
    },
	props: {
		msg: String
	},
	methods: {
		getProgramasPost() {
			this.UAescogida=0;
			this.MostrarFiltrosTipoPro=0;
            this.ss.listVistaPostgradoPrograma().then(
                (result) => {
                    let response = result.data;
                    this.programasPost = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
		},


		

		
		getProgramasPorUA(id,idSaga) {
			this.MostrarFiltrosTipoPro=1;
			this.UAescogida=id;
			this.UAsagaEscogida=idSaga;
			let params = {
				id: id,
				idSaga:idSaga,
			}

			console.log(params);
            this.ss.listVistaPostgradoporUA(params).then(
                (result) => {
                    let response = result.data;
                    this.programasPost = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
		},

		fitroSegunTipo(idTipo){
			console.log(idTipo);
			console.log(this.UAescogida);

			let params = {
				id: this.UAescogida,
				idSaga:this.UAsagaEscogida,
				tipoProg:idTipo,
			}
			console.log(params);
			this.ss.listVistaPostgradoporUAyTipo(params).then(
                (result) => {
                    let response = result.data;
                    this.programasPost = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
		},

		verMas(id){
			console.log("ver mas"+id);
			this.$router.push('VerProgramaPostgrado?id='+id) 
			
			//router.go('/VerificarDare');
		},
		cerrarInfo(){
			this.$refs['info'].hide();
		},

		modalLogin(){
			this.$refs['info'].hide();
			this.$root.$refs.MenuExterno.abrirModalLogin();
			// this.$forceUpdate();
		},

		modalRegistro(){
			this.$refs['info'].hide();
			this.$root.$refs.MenuExterno.abrirModaReg();
			// this.$forceUpdate();
		},
		
		onSlideStart() {
			this.sliding = true
		},
		onSlideEnd() {
			this.sliding = false
		},

		enviarInfo(){
			console.log("datos persona informacion");
			console.log(this.datosInfo);
			this.datosInfo={};
		}

	},
	mounted() {
		this.auth = JSON.parse(localStorage.getItem("persona"));
		window.scrollTo(0, 0);
		this.getProgramasPost();
		if (!this.auth) {
            this.$refs['info'].show();
        } 
		
		
	}
}