import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
import Loader from "@/components/Loader";
window.$ = window.jQuery = require("jquery");

export default {
    name: "PersonaDocumentoPage",
    data() {
        let ss = new MainService();
        return {
            msg: "PersonaDocumentoPage",
            ss: ss,
            ajax: {
                "url": ss.indexPersona(),
                headers: ss.getToken(),
            },
            ajaxDocumentosB: {
                "url": ss.indexPersona(),
                headers: ss.getToken(),
            },
            columns: [
                { data: 'id', name: 'id', orderable: false, searchable: false, visible: false },
                { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N', orderable: false, searchable: false },
                { data: 'UnidadAcademica', name: 'ua.UnidadAcademica', title: 'UnidadAcademica' },
                { data: 'Persona', name: 'p.Persona', title: 'Persona' },
                { data: 'Rol', name: 'r.Rol', title: 'Rol' },
                { data: 'email', name: 'p.email', title: 'Email' },
                { data: 'nroCI', name: 'p.nroCI', title: 'C.I.' },
                {
                    data: 'action',
                    orderable: false,
                    title: 'Acciones',
                    searchable: false
                },
            ],
            columnsDocumentosB: [
                { data: 'id', name: 'id', orderable: false, searchable: false, visible: false },
                { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N', orderable: false, searchable: false },
                { data: 'UnidadAcademica', name: 'ua.UnidadAcademica', title: 'UnidadAcademica' },
                { data: 'Persona', name: 'p.Persona', title: 'Persona' },
                { data: 'Rol', name: 'r.Rol', title: 'Rol' },
                { data: 'email', name: 'p.email', title: 'Email' },
                { data: 'nroCI', name: 'p.nroCI', title: 'C.I.' },
                {
                    data: 'action',
                    orderable: false,
                    title: 'Acciones',
                    searchable: false
                },
            ],
            personadocumento: {},
            persona: {},
            search: '',
            isLoading: false,
            errorBag: {},
            documento:{},
            headers: [
                {text: 'Documento',align: 'start',filterable: false,value: 'Documento',},
                { text: 'Descripcion', value: 'Descripcion' },
                { text: 'Grupo', value: 'grupo_documento.GrupoDocumento' },
                { text: 'Tipo', value: 'tipo_documento.TipoDocumento'},
                { text: 'Cantidad', value: 'cantidad' },
                { text: 'Acciones', value: 'action' },
              ],
            documentoFields: [
                'Nro',
                {key:'Documento',label:'Documento',sortable:true}, 
                {key:'Descripcion',label:'Descripcion',sortable:true}, 
                'Tipo', 
                {key: 'cantidad',label: 'Cantidad',variant: 'secondary',sortable: true,class:'centradito'}, 
                'Acciones']
        };
    },
    methods: {
        rowClass(item, type) {
            if (item && type === 'row') {
              if (item.TipoDocumento === 1) {
                return 'ocultito'
              } else {
                return 'd-none'
              } 
            } else {
              return null
            }
          },
        getDocumento(grupo) {
            console.log('estoy aca', grupo);
            this.ss.listDocumento({params:{'grupo':grupo}}).then(
                (result) => {
                    let response = result.data;
                    this.documento = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        newPersonaDocumento() {
            this.personadocumento = {};
            this.$refs['frm-personadocumento'].show();
        },
        showPersona(id) {
            console.log('iniciando', id);
            this.isLoading=true;
            this.ss.showPersona(id).then(
                (result) => {
                    let response = result.data;
                    console.log('estoy aca', response.data);
                    this.persona = response.data;
                    this.personadocumento = this.persona.documentacion;
                    this.$refs['view-personadocumento'].show();
                    this.isLoading=false;
                    this.getDocumento(1);
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        },
        aplicarFiltroDocumentoB(){
            console.log("se aplican los filtros");
            let idPersona = this.persona.id;
            this.ajaxDocumentosB.data = function (d) {
                d.Persona = idPersona;
            }
            this.$refs['datatable-documentosbasicos'].reloadAjax(this.ajaxDocumentosB);

        },
        editPersonaDocumento() {
            this.$refs['frm-personadocumento'].show();
            this.$refs['view-personadocumento'].hide();
        },
        cancelPersonaDocumento() {
            if (this.estado.id) {
                this.$refs['view-personadocumento'].show();
            }
            this.$refs['frm-personadocumento'].hide();
        },
        savePersonaDocumento() {
            this.ss.storePersonaDocumento(this.personadocumento).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    console.log(response);
                    this.$refs['frm-personadocumento'].hide();
                    this.$refs['datatable-personadocumento'].reload();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        deletePersonaDocumento() {
            this.$swal({
                title: "Estás seguro que deseas eliminar?",
                text: "Esta acción es irreversible!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then((willDelete) => {
                    if (willDelete) {
                        this.ss.destroyPersonaDocumento(this.personadocumento)
                            .then((result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )
                                this.$refs['view-personadocumento'].hide();
                                this.$refs['datatable-PersonaDocumento'].reload();
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },
        newDocumento() {
            this.personadocumento = {};
            this.$refs['frm-documento'].show();
        },
        saveDocumento(){

        },
        cancelDocuemnto(){

        },
        showDocumentacion() {
            this.$refs['view-documento'].show();
            this.aplicarFiltroDocumentoB();
        },
        draw() {
            window.$('.btn-datatable-Persona').on('click', (evt) => {
                const data = window.$(evt.target)[0].id;
                this.showPersona(data);
            });
        },
        drawDocumentosB() {
            window.$('.btn-datatable-Persona').on('click', (evt) => {
                const data = window.$(evt.target)[0].id;
                this.showPersona(data);
            });
        }
    },
    components: {
        dataTable,
        Loader
    },
    mounted() {
        var persona = JSON.parse(localStorage.getItem('persona'));
        if (!persona) {
          this.$router.push('/Login');
        } else {
            this.getDocumento();
        }
    }
};
