var axios = require('axios');

export default class MainService {

    constructor() {
        var token = JSON.parse(localStorage.getItem('token'));
        axios.defaults.baseURL = process.env.VUE_APP_MAIN_SERVICE;
        if (token) {
            axios.defaults.headers.common = {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }else{
            axios.defaults.headers.common = {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }
    }

    getToken() {
        return axios.defaults.headers.common;
    }

    login(item) {
        return axios.post('api/login', item);
    }

    uploadFile(item) {
        return axios.post('api/uploadFile', item);
    }

    loginToken365(item) {
        return axios.post('api/loginToken365', item);
    }

    indexPersona() {
        return axios.defaults.baseURL + 'api/Persona/index';
    }

    showPersona(id) {
        return axios.get('api/Persona/show?id=' + id);
    }

    listPersona() {
        return axios.get('api/Persona/list');
    }

    storePersona(item) {
        return axios.post('api/Persona/store', item);
    }

    personaStore(item) {
        return axios.post('api/Persona/personaStore', item);
    }

    destroyPersona(item) {
        return axios.post('api/Persona/destroy', item);
    }

    changePassword(item) {
        return axios.post('api/Persona/changePassword', item);
    }

    confirmacionPersona(item) {
        return axios.post('api/Persona/confirmacionPersona', item);
    }

    showUnidadAcademica(id) {
        return axios.get('api/UnidadAcademica/show?id=' + id);
    }

    indexUnidadAcademica() {
        return axios.defaults.baseURL + 'api/UnidadAcademica/index';
    }

    listUnidadAcademica() {
        return axios.get('api/UnidadAcademica/list');
    }

    storeUnidadAcademica(item) {
        return axios.post('api/UnidadAcademica/store', item);
    }

    destroyUnidadAcademica(item) {
        return axios.post('api/UnidadAcademica/destroy', item);
    }

    showRol(id) {
        return axios.get('api/Rol/show?id=' + id);
    }

    indexRol() {
        return axios.defaults.baseURL + 'api/Rol/index';
    }

    listRol() {
        return axios.get('api/Rol/list');
    }

    storeRol(item) {
        return axios.post('api/Rol/store', item);
    }

    destroyRol(item) {
        return axios.post('api/Rol/destroy', item);
    }

    //TipoReporte
    showTipoReporte(id) {
        return axios.get('api/TipoReporte/show?id=' + id);
    }

    indexTipoReporte() {
        return axios.defaults.baseURL + 'api/TipoReporte/index';
    }

    listTipoReporte() {
        return axios.get('api/TipoReporte/list');
    }

    storeTipoReporte(item) {
        return axios.post('api/TipoReporte/store', item);
    }

    destroyTipoReporte(item) {
        return axios.post('api/TipoReporte/destroy', item);
    }

    generateTipoReporte(item) {
        return axios.post('api/TipoReporte/generate', item);
    }
    ////////////////////////////////Estado

    showEstado(id) {
        return axios.get('api/Estado/show?id=' + id);
    }

    indexEstado() {
        return axios.defaults.baseURL + 'api/Estado/index';
    }

    listEstado() {
        return axios.get('api/Estado/list');
    }

    storeEstado(item) {
        return axios.post('api/Estado/store', item);
    }

    destroyEstado(item) {
        return axios.post('api/Estado/destroy', item);
    }
    ////////////////////////////////Convocatoria

    showConvocatoria(id) {
        return axios.get('api/Convocatoria/show?id=' + id);
    }

    indexConvocatoria() {
        return axios.defaults.baseURL + 'api/Convocatoria/index';
    }

    listConvocatoria() {
        return axios.get('api/Convocatoria/list');
    }

    storeConvocatoria(item) {
        return axios.post('api/Convocatoria/store', item);
    }

    destroyConvocatoria(item) {
        return axios.post('api/Convocatoria/destroy', item);
    }
     ////////////////////////////////Tipo Nivel Academico

     showTipoNivelAcademico(id) {
        return axios.get('api/TipoNivelAcademico/show?id=' + id);
    }

    indexTipoNivelAcademico() {
        return axios.defaults.baseURL + 'api/TipoNivelAcademico/index';
    }

    listTipoNivelAcademico() {
        return axios.get('api/TipoNivelAcademico/list');
    }

    storeTipoNivelAcademico(item) {
        return axios.post('api/TipoNivelAcademico/store', item);
    }

    destroyTipoNivelAcademico(item) {
        return axios.post('api/TipoNivelAcademico/destroy', item);
    }
     //////////////////////////////// Nivel Academico

     showNivelAcademico(id) {
        return axios.get('api/NivelAcademico/show?id=' + id);
    }

    indexNivelAcademico() {
        return axios.defaults.baseURL + 'api/NivelAcademico/index';
    }

    listNivelAcademico() {
        return axios.get('api/NivelAcademico/list');
    }

    storeNivelAcademico(item) {
        return axios.post('api/NivelAcademico/store', item);
    }

    destroyNivelAcademico(item) {
        return axios.post('api/NivelAcademico/destroy', item);
    }
    //////////////////////////////// Carrera

    showCarrera(id) {
        return axios.get('api/Carrera/show?id=' + id);
    }

    indexCarrera() {
        return axios.defaults.baseURL + 'api/Carrera/index';
    }

    listCarrera() {
        return axios.get('api/Carrera/list');
    }

    storeCarrera(item) {
        return axios.post('api/Carrera/store', item);
    }

    destroyCarrera(item) {
        return axios.post('api/Carrera/destroy', item);
    }
    //////////////////////////////// Programa

    showPrograma(id) {
        return axios.get('api/Programa/show?id=' + id);
    }

    indexPrograma() {
        return axios.defaults.baseURL + 'api/Programa/index';
    }

    listPrograma() {
        return axios.get('api/Programa/list');
    }

    storePrograma(item) {
        return axios.post('api/Programa/store', item);
    }

    destroyPrograma(item) {
        return axios.post('api/Programa/destroy', item);
    }
     //////////////////////////////// Asignatura

     showAsignatura(id) {
        return axios.get('api/Asignatura/show?id=' + id);
    }

    indexAsignatura() {
        return axios.defaults.baseURL + 'api/Asignatura/index';
    }

    listAsignatura() {
        return axios.get('api/Asignatura/list');
    }

    storeAsignatura(item) {
        return axios.post('api/Asignatura/store', item);
    }

    destroyAsignatura(item) {
        return axios.post('api/Asignatura/destroy', item);
    }

    //////////////////////////////// Materia

    showMateria(id) {
        return axios.get('api/Materia/show?id=' + id);
    }

    indexMateria() {
        return axios.defaults.baseURL + 'api/Materia/index';
    }

    listMateria() {
        return axios.get('api/Materia/list');
    }

    storeMateria(item) {
        return axios.post('api/Materia/store', item);
    }

    destroyMateria(item) {
        return axios.post('api/Materia/destroy', item);
    }

     //////////////////////////////// Convocatoria Asignatura

     showConvocatoriaAsignatura(id) {
        return axios.get('api/ConvocatoriaAsignatura/show?id=' + id);
    }

    indexConvocatoriaAsignatura() {
        return axios.defaults.baseURL + 'api/ConvocatoriaAsignatura/index';
    }

    listConvocatoriaAsignatura() {
        return axios.get('api/ConvocatoriaAsignatura/list');
    }

    storeConvocatoriaAsignatura(item) {
        return axios.post('api/ConvocatoriaAsignatura/store', item);
    }

    destroyConvocatoriaAsignatura(item) {
        return axios.post('api/ConvocatoriaAsignatura/destroy', item);
    }

    // materiasConvocatoriaVigente() {
    //     return axios.get('api/ConvocatoriaAsignatura/materiasConvocatoriaVigente');
    // }

    materiasConvocatoriaVigente() {
        return axios.defaults.baseURL + 'api/ConvocatoriaAsignatura/materiasConvocatoriaVigente';
    }

    //////////////////////////////// TipoDocumento

    showTipoDocumento(id) {
        return axios.get('api/TipoDocumento/show?id=' + id);
    }

    indexTipoDocumento() {
        return axios.defaults.baseURL + 'api/TipoDocumento/index';
    }

    listTipoDocumento() {
        return axios.get('api/TipoDocumento/list');
    }

    storeTipoDocumento(item) {
        return axios.post('api/TipoDocumento/store', item);
    }

    destroyTipoDocumento(item) {
        return axios.post('api/TipoDocumento/destroy', item);
    }

    //////////////////////////////// GrupoDocumento

    showGrupoDocumento(id) {
        return axios.get('api/GrupoDocumento/show?id=' + id);
    }

    indexGrupoDocumento() {
        return axios.defaults.baseURL + 'api/GrupoDocumento/index';
    }

    listGrupoDocumento() {
        return axios.get('api/GrupoDocumento/list');
    }

    storeGrupoDocumento(item) {
        return axios.post('api/GrupoDocumento/store', item);
    }

    destroyGrupoDocumento(item) {
        return axios.post('api/GrupoDocumento/destroy', item);
    }

    //////////////////////////////// EstadoDocumento

    showEstadoDocumento(id) {
        return axios.get('api/EstadoDocumento/show?id=' + id);
    }

    indexEstadoDocumento() {
        return axios.defaults.baseURL + 'api/EstadoDocumento/index';
    }

    listEstadoDocumento() {
        return axios.get('api/EstadoDocumento/list');
    }

    storeEstadoDocumento(item) {
        return axios.post('api/EstadoDocumento/store', item);
    }

    destroyEstadoDocumento(item) {
        return axios.post('api/EstadoDocumento/destroy', item);
    }

    //////////////////////////////// Documento

    showDocumento(id) {
        return axios.get('api/Documento/show?id=' + id);
    }

    indexDocumento() {
        return axios.defaults.baseURL + 'api/Documento/index';
    }

    listDocumento(item) {
        return axios.get('api/Documento/list', item);
    }

    storeDocumento(item) {
        return axios.post('api/Documento/store', item);
    }

    destroyDocumento(item) {
        return axios.post('api/Documento/destroy', item);
    }

    //////////////////////////////// PersonaDocumento

    showPersonaDocumento(id) {
        return axios.get('api/PersonaDocumento/show?id=' + id);
    }

    indexPersonaDocumento() {
        return axios.defaults.baseURL + 'api/PersonaDocumento/index';
    }

    listPersonaDocumento() {
        return axios.get('api/PersonaDocumento/list');
    }

    storePersonaDocumento(item) {
        return axios.post('api/PersonaDocumento/store', item);
    }

    destroyPersonaDocumento(item) {
        return axios.post('api/PersonaDocumento/destroy', item);
    }

    //////////////////////////////// Postulacion

    showPostulacion(id) {
        return axios.get('api/Postulacion/show?id=' + id);
    }

    indexPostulacion() {
        return axios.defaults.baseURL + 'api/Postulacion/index';
    }

    listPostulacion() {
        return axios.get('api/Postulacion/list');
    }

    storePostulacion(item) {
        return axios.post('api/Postulacion/store', item);
    }

    destroyPostulacion(item) {
        return axios.post('api/Postulacion/destroy', item);
    }

    //////////////////////////////// Merito

    showMerito(id) {
        return axios.get('api/Merito/show?id=' + id);
    }

    indexMerito() {
        return axios.defaults.baseURL + 'api/Merito/index';
    }

    listMerito() {
        return axios.get('api/Merito/list');
    }

    storeMerito(item) {
        return axios.post('api/Merito/store', item);
    }

    destroyMerito(item) {
        return axios.post('api/Merito/destroy', item);
    }

    //////////////////////////////// Componente

    showComponente(id) {
        return axios.get('api/Componente/show?id=' + id);
    }

    indexComponente() {
        return axios.defaults.baseURL + 'api/Componente/index';
    }

    listComponente() {
        return axios.get('api/Componente/list');
    }

    storeComponente(item) {
        return axios.post('api/Componente/store', item);
    }

    destroyComponente(item) {
        return axios.post('api/Componente/destroy', item);
    }

    //////////////////////////////// TipoComision

    showTipoComision(id) {
        return axios.get('api/TipoComision/show?id=' + id);
    }

    indexTipoComision() {
        return axios.defaults.baseURL + 'api/TipoComision/index';
    }

    listTipoComision() {
        return axios.get('api/TipoComision/list');
    }

    storeTipoComision(item) {
        return axios.post('api/TipoComision/store', item);
    }

    destroyTipoComision(item) {
        return axios.post('api/TipoComision/destroy', item);
    }

    //////////////////////////////// ComisionMerito

    showComisionMerito(id) {
        return axios.get('api/ComisionMerito/show?id=' + id);
    }

    indexComisionMerito() {
        return axios.defaults.baseURL + 'api/ComisionMerito/index';
    }

    listComisionMerito() {
        return axios.get('api/ComisionMerito/list');
    }

    storeComisionMerito(item) {
        return axios.post('api/ComisionMerito/store', item);
    }

    destroyComisionMerito(item) {
        return axios.post('api/ComisionMerito/destroy', item);
    }

    //////////////////////////////// ComisionCompetencia

    showComisionCompetencia(id) {
        return axios.get('api/ComisionCompetencia/show?id=' + id);
    }

    indexComisionCompetencia() {
        return axios.defaults.baseURL + 'api/ComisionCompetencia/index';
    }

    listComisionCompetencia() {
        return axios.get('api/ComisionCompetencia/list');
    }

    storeComisionCompetencia(item) {
        return axios.post('api/ComisionCompetencia/store', item);
    }

    destroyComisionCompetencia(item) {
        return axios.post('api/ComisionCompetencia/destroy', item);
    }

    //////////////////////////////// EvaluacionMerito

    showEvaluacionMerito(id) {
        return axios.get('api/EvaluacionMerito/show?id=' + id);
    }

    indexEvaluacionMerito() {
        return axios.defaults.baseURL + 'api/EvaluacionMerito/index';
    }

    listEvaluacionMerito() {
        return axios.get('api/EvaluacionMerito/list');
    }

    storeEvaluacionMerito(item) {
        return axios.post('api/EvaluacionMerito/store', item);
    }

    destroyEvaluacionMerito(item) {
        return axios.post('api/EvaluacionMerito/destroy', item);
    }

    //////////////////////////////// EvaluacionCompetencia

    showEvaluacionCompetencia(id) {
        return axios.get('api/EvaluacionCompetencia/show?id=' + id);
    }

    indexEvaluacionCompetencia() {
        return axios.defaults.baseURL + 'api/EvaluacionCompetencia/index';
    }

    listEvaluacionCompetencia() {
        return axios.get('api/EvaluacionCompetencia/list');
    }

    storeEvaluacionCompetencia(item) {
        return axios.post('api/EvaluacionCompetencia/store', item);
    }

    destroyEvaluacionCompetencia(item) {
        return axios.post('api/EvaluacionCompetencia/destroy', item);
    }
}