import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
import Loader from "@/components/Loader";
window.$ = window.jQuery = require("jquery");

export default {
    name: "ConvocatoriaPage",
    data() {
        let ss = new MainService();
        return {
            msg: "ConvocatoriaPage",
            ss: ss,
            ajax: {
                "url": ss.indexConvocatoria(),
                headers: ss.getToken(),
            },
            ajaxAsignatura: {
                "url": ss.indexConvocatoriaAsignatura(),
                headers: ss.getToken(),
            },
            columns: [
                { data: 'id', name: 'id', orderable: false, searchable: false, visible: false },
                { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N', orderable: false, searchable: false },
                { data: 'Convocatoria', name: 'Convocatoria', title: 'Convocatoria' },
                { data: 'Descripcion', name: 'Descripcion', title: 'Descripción' },
                { data: 'FechaInicio', name: 'FechaInicio', title: 'Fecha Inicio' },
                { data: 'FechaFin', name: 'FechaFin', title: 'Fecha Fin' },
                { data: 'Observacion', name: 'Observacion', title: 'Observación' },
                { data: 'Estado', name: 'Estado', title: 'Estado' },
                { data: 'Activo', name: 'Activo', title: 'Activo' },
                {
                    data: 'action',
                    orderable: false,
                    title: 'Acciones',
                    searchable: false
                },
            ],
            columnsAsignatura: [
                { data: 'id', name: 'id', orderable: false, searchable: false, visible: false },
                { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N', orderable: false, searchable: false },
                { data: 'Materia', name: 'Materia', title: 'Asignatura' },
                { data: 'Detalle', name: 'Detalle', title: 'Detalle' },
                { data: 'CargaH', name: 'CargaH', title: 'CargaH' },
                { data: 'Requerimientos', name: 'Requerimientos', title: 'Requerimientos' },
                { data: 'Observacion', name: 'Observacion', title: 'Observacion' },
                {
                    data: 'action',
                    orderable: false,
                    title: 'Acciones',
                    searchable: false
                },
            ],
            convocatorias: [],
            convocatoria: {},

            estados: [],
            estado: {},

            isLoading: false,
            errorBag: {},
            convocatoriaasignatura:{},
            asignaturas: []

        };
    },
    methods: {
        newConvocatoriaAsignatura() {
            this.convocatoriaasignatura = {};
            this.$refs['frm-convocatoriaasignatura'].show();
        },
        newConvocatoria() {
            this.convocatoria = {};
            this.$refs['frm-convocatoria'].show();
        },
        getAsignatura() {
            this.ss.listAsignatura().then(
                (result) => {
                    let response = result.data;
                    this.asignaturas = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        showConvocatoria(id) {
            this.isLoading=true;
            this.ss.showConvocatoria(id).then(
                (result) => {
                    let response = result.data;
                    this.convocatoria = response.data;
                    this.$refs['view-convocatoria'].show();
                    this.isLoading=false;
                    this.aplicarFiltroAsignatura();

                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        },
        editConvocatoria() {
            this.$refs['frm-convocatoria'].show();
            this.$refs['view-convocatoria'].hide();
        },
        cancelConvocatoria() {
            if (this.convocatoria.id) {
                this.$refs['view-convocatoria'].show();
            }
            this.$refs['frm-convocatoria'].hide();
        },
        aplicarFiltroAsignatura(){
            console.log("se aplican los filtros");
            let idConvocatoria = this.convocatoria.id;
            this.ajaxAsignatura.data = function (d) {
                d.Convocatoria = idConvocatoria;
            }
            this.$refs['datatable-asignaturaconvocatoria'].reloadAjax(this.ajaxAsignatura);
        },
        saveConvocatoria() {
            this.ss.storeConvocatoria(this.convocatoria).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    console.log(response);
                    this.$refs['frm-convocatoria'].hide();
                    this.$refs['datatable-convocatoria'].reload();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        saveConvocatoriaAsignatura() {
            this.ss.storeConvocatoriaAsignatura(this.convocatoriaasignatura).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    console.log(response);
                    this.$refs['frm-convocatoriaasignatura'].hide();
                    this.$refs['datatable-convocatoriaasignatura'].reload();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        cancelConvocatoriaAsignatura() {
            if (this.convocatoriaasignatura.id) {
                this.$refs['view-convocatoriaasignatura'].show();
            }
            this.$refs['frm-convocatoriaasignatura'].hide();
        },
        deleteConvocatoria() {
            this.$swal({
                title: "Estás seguro que deseas eliminar?",
                text: "Esta acción es irreversible!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then((willDelete) => {
                    if (willDelete) {
                        this.ss.destroyConvocatoria(this.convocatoria)
                            .then((result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )
                                this.$refs['view-convocatoria'].hide();
                                this.$refs['datatable-convocatoria'].reload();
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },
        getEstado() {
            this.ss.listEstado().then(
                (result) => {
                    let response = result.data;
                    this.estados = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        draw() {
            window.$('.btn-datatable-Convocatoria').on('click', (evt) => {
                const data = window.$(evt.target)[0].id;
                this.showConvocatoria(data);
            });
        },
        drawAsignatura() {
            window.$('.btn-datatable-Persona').on('click', (evt) => {
                const data = window.$(evt.target)[0].id;
                this.showPersona(data);
            });
        }
    },
    components: {
        dataTable,
        Loader
    },
    mounted() {
        var persona = JSON.parse(localStorage.getItem('persona'));
        if (!persona) {
          this.$router.push('/Login');
        }

        this.getEstado();
        this.getAsignatura();

    }
};
