<template>
  <!-- Image and text  bg-transparent-->
  <div>
    <link href="/assets/estilos/bootstrap/css/bootstrap.min.css" rel="stylesheet">
    <link href="/assets/estilos/icofont/icofont.min.css" rel="stylesheet">
    <link href="/assets/estilos/boxicons/css/boxicons.min.css" rel="stylesheet">
    <link href="/assets/estilos/remixicon/remixicon.css" rel="stylesheet">
    <link href="/assets/estilos/owl.carousel/assets/owl.carousel.min.css" rel="stylesheet">
    <link href="/assets/estilos/venobox/venobox.css" rel="stylesheet">
    
    <link href="/assets/css/style2.css" rel="stylesheet">
    
    <!-- ======= Footer ======= -->
    <footer id="footer">

        <div class="footer-top">

        <div class="container">

            <div class="row  justify-content-center">
            <div class="col-lg-2">
                <h3> - EMI - </h3>
                <!-- <h4 class="mt-5 text-black"> <i>Encuéntranos en nuestras redes sociales</i> </h4> -->
                <br>
            </div>
            </div>

            <div class="social-links display-4">
            <a href="#" class="twitter"><i class="bx bxl-twitter"></i></a>
            <a href="#" class="facebook"><i class="bx bxl-facebook"></i></a>
            <a href="#" class="instagram"><i class="bx bxl-instagram"></i></a>
            <a href="#" class="google-plus"><i class="bx bxl-skype"></i></a>
            <a href="#" class="linkedin"><i class="bx bxl-linkedin"></i></a>
            </div>

        </div>
        </div>

        <div class="container footer-bottom clearfix">
        <div class="copyright">
            &copy; Copyright <strong><span>DNTIC</span></strong>. All Rights Reserved
        </div>
        <div class="credits">

            Designed by <a href="#">Escuela Militar de Ingeniería - DNTIC</a>
        </div>
        </div>
    </footer>
    </div>
  <!-- End Header -->
</template>

<script>
export default {
    name: "FooterExterno",
    data() {
        return {
        MenuExterno: {},
                errorBag: {},
                nuevaPersona:{},
                loginCredenciales: {},
        };
    },
    mounted() {

    },

	methods: {

    }
};
</script>