import Vue from 'vue'
import Router from 'vue-router'
import LoginPage from '@/pages/Login/LoginPage.vue'
import UnidadAcademicaPage from '@/pages/UnidadAcademica/UnidadAcademicaPage.vue'
import RolPage from '@/pages/Rol/RolPage.vue'
import PersonaPage from '@/pages/Persona/PersonaPage.vue'
//import PrincipalPage from '@/pages/Principal/PrincipalPage.vue'
import ProfilePage from '@/pages/Profile/ProfilePage.vue'
import TipoReportePage from '@/pages/TipoReporte/TipoReportePage.vue'
import TipoReporteVerPage from '@/pages/TipoReporteVer/TipoReporteVerPage.vue'

import EstadoPage from '@/pages/Estado/EstadoPage.vue'
import ConvocatoriaPage from '@/pages/Convocatoria/ConvocatoriaPage.vue'
import TipoNivelAcademicoPage from '@/pages/TipoNivelAcademico/TipoNivelAcademicoPage.vue'
import NivelAcademicoPage from '@/pages/NivelAcademico/NivelAcademicoPage.vue'
import CarreraPage from '@/pages/Carrera/CarreraPage.vue'
import ProgramaPage from '@/pages/Programa/ProgramaPage.vue'
import AsignaturaPage from '@/pages/Asignatura/AsignaturaPage.vue'
import MateriaPage from '@/pages/Materia/MateriaPage.vue'
import ConvocatoriaAsignaturaPage from '@/pages/ConvocatoriaAsignatura/ConvocatoriaAsignaturaPage.vue'

import TipoDocumentoPage from '@/pages/TipoDocumento/TipoDocumentoPage.vue'
import GrupoDocumentoPage from '@/pages/GrupoDocumento/GrupoDocumentoPage.vue'
import EstadoDocumentoPage from '@/pages/EstadoDocumento/EstadoDocumentoPage.vue'
import DocumentoPage from '@/pages/Documento/DocumentoPage.vue'
import PersonaDocumentoPage from '@/pages/PersonaDocumento/PersonaDocumentoPage.vue'
import PostulacionPage from '@/pages/Postulacion/PostulacionPage.vue'
import MeritoPage from '@/pages/Merito/MeritoPage.vue'
import ComponentePage from '@/pages/Componente/ComponentePage.vue'
import TipoComisionPage from '@/pages/TipoComision/TipoComisionPage.vue'
import ComisionMeritoPage from '@/pages/ComisionMerito/ComisionMeritoPage.vue'
import ComisionCompetenciaPage from '@/pages/ComisionCompetencia/ComisionCompetenciaPage.vue'
import EvaluacionMeritoPage from '@/pages/EvaluacionMerito/EvaluacionMeritoPage.vue'
import EvaluacionCompetenciaPage from '@/pages/EvaluacionCompetencia/EvaluacionCompetenciaPage.vue'
import InscribetePage from '@/pages/Inscribete/InscribetePage.vue'
import MainPage from '@/pages/Main/MainPage.vue'

Vue.use(Router)

export default new Router({
  routes: [
    {
      path: '/',
      name: 'MainPage',
      component: MainPage
    }, 
    {
      path: '/UnidadAcademica',
      name: 'Unidad Academica',
      component: UnidadAcademicaPage
    },
    {
      path: '/Rol',
      name: 'Rol',
      component: RolPage
    },
    {
      path: '/Persona',
      name: 'Persona',
      component: PersonaPage
    },
    {
      path: '/Profile',
      name: 'Perfil',
      component: ProfilePage
    },
    {
      path: '/Login',
      name: 'Login',
      component: LoginPage
    },
    {
      path: '/TipoReporte',
      name: 'TipoReporte',
      component: TipoReportePage
    },
    {
      path: '/TipoReporteVer',
      name: 'TipoReporteVer',
      component: TipoReporteVerPage
    },
    {
      path: '/Estado',
      name: 'Estado',
      component: EstadoPage
    },
    {
      path: '/Convocatoria',
      name: 'Convocatoria',
      component: ConvocatoriaPage
    },
    {
      path: '/TipoNivelAcademico',
      name: 'TipoNivelAcademico',
      component: TipoNivelAcademicoPage
    },
    {
      path: '/NivelAcademico',
      name: 'NivelAcademico',
      component: NivelAcademicoPage
    },
    {
      path: '/Carrera',
      name: 'Carrera',
      component: CarreraPage
    },
    {
      path: '/Programa',
      name: 'Programa',
      component: ProgramaPage
    },
    {
      path: '/Asignatura',
      name: 'Asignatura',
      component: AsignaturaPage
    },
    {
      path: '/Materia',
      name: 'Materia',
      component: MateriaPage
    },
    {
      path: '/ConvocatoriaAsignatura',
      name: 'ConvocatoriaAsignatura',
      component: ConvocatoriaAsignaturaPage
    },
    {
      path: '/TipoDocumento',
      name: 'TipoDocumento',
      component: TipoDocumentoPage
    },
    {
      path: '/GrupoDocumento',
      name: 'GrupoDocumento',
      component: GrupoDocumentoPage
    },
    {
      path: '/EstadoDocumento',
      name: 'EstadoDocumento',
      component: EstadoDocumentoPage
    },
    {
      path: '/Documento',
      name: 'Documento',
      component: DocumentoPage
    },
    {
      path: '/PersonaDocumento',
      name: 'PersonaDocumento',
      component: PersonaDocumentoPage
    },
    {
      path: '/Postulacion',
      name: 'Postulacion',
      component: PostulacionPage
    },
    {
      path: '/Merito',
      name: 'Merito',
      component: MeritoPage
    },
    {
      path: '/Componente',
      name: 'Componente',
      component: ComponentePage
    },
    {
      path: '/TipoComision',
      name: 'TipoComision',
      component: TipoComisionPage
    },
    {
      path: '/ComisionMerito',
      name: 'ComisionMerito',
      component: ComisionMeritoPage
    },
    {
      path: '/ComisionCompetencia',
      name: 'ComisionCompetencia',
      component: ComisionCompetenciaPage
    },
    {
      path: '/EvaluacionMerito',
      name: 'EvaluacionMerito',
      component: EvaluacionMeritoPage
    },
    {
      path: '/EvaluacionCompetencia',
      name: 'EvaluacionCompetencia',
      component: EvaluacionCompetenciaPage
    },
    {
      path: '/Inscribete',
      name: 'Inscribete',
      component: InscribetePage
    },
    {
      path: '/Main',
      name: 'Main',
      component: MainPage
    }
  ]
})