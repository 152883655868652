import MainService from "@/services/MainService.js";
import dataTable from "@/components/Datatable";
import Loader from "@/components/Loader";
window.$ = window.jQuery = require("jquery");

export default {
    name: "ConvocatoriaAsignaturaPage",
    data() {
        let ss = new MainService();
        return {
            msg: "ConvocatoriaPage",
            ss: ss,
            ajax: {
                "url": ss.indexConvocatoriaAsignatura(),
                headers: ss.getToken(),
            },
            columns: [
                { data: 'id', name: 'id', orderable: false, searchable: false, visible: false },
                { data: 'DT_RowIndex', name: 'DT_RowIndex', title: 'N', orderable: false, searchable: false },
                { data: 'Detalle', name: 'Detalle', title: 'Detalle' },
                { data: 'CargaH', name: 'CargaH', title: 'CargaH' },
                { data: 'Requerimientos', name: 'Requerimientos', title: 'Requerimientos' },
                { data: 'Observacion', name: 'Observacion', title: 'Observacion' },
                { data: 'Convocatoria', name: 'Convocatoria', title: 'Convocatoria' },
                { data: 'Asignatura', name: 'Asignatura', title: 'Asignatura' },
                {
                    data: 'action',
                    orderable: false,
                    title: 'Acciones',
                    searchable: false
                },
            ],

            convocatorias: [],
            convocatoria: {},

            asignaturas: [],
            asignatura: {},

            convocatoriaasignaturas: [],
            convocatoriaasignatura: {},

            isLoading: false,
            errorBag: {}
        };
    },
    methods: {
        newConvocatoriaAsignatura() {
            this.convocatoriaasignatura = {};
            this.$refs['frm-convocatoriaasignatura'].show();
        },
        showConvocatoriaAsignatura(id) {
            console.log(id);
            this.isLoading=true;
            this.ss.showConvocatoriaAsignatura(id).then(
                (result) => {
                    let response = result.data;
                    this.convocatoriaasignatura = response.data;
                    this.$refs['view-convocatoriaasignatura'].show();
                    this.isLoading=false;
                }
            ).catch(error => {
                console.log(error);
                this.isLoading=false;
            });
        },
        editConvocatoriaAsignatura() {
            this.$refs['frm-convocatoriaasignatura'].show();
            this.$refs['view-convocatoriaasignatura'].hide();
        },
        cancelConvocatoriaAsignatura() {
            if (this.convocatoriaasignatura.id) {
                this.$refs['view-convocatoriaasignatura'].show();
            }
            this.$refs['frm-convocatoriaasignatura'].hide();
        },
        saveConvocatoriaAsignatura() {
            this.ss.storeConvocatoriaAsignatura(this.convocatoriaasignatura).then(
                (result) => {
                    console.log(result);
                    let response = result.data;
                    this.$bvToast.toast(
                        response.msg,
                        {
                            title: 'Correcto',
                            variant: 'success',
                            autoHideDelay: 5000
                        }
                    )
                    //this.$refs['view-consulta'].show(); //para volver al modal
                    console.log(response);
                    this.$refs['frm-convocatoriaasignatura'].hide();
                    this.$refs['datatable-convocatoriaasignatura'].reload();
                })
                .catch((error) => {
                    this.errorBag = error.response.data.errors;
                    this.$bvToast.toast(
                        'Problema al Guardar el Registro, favor verificar los Datos',
                        {
                            title: 'Error',
                            variant: 'danger',
                            autoHideDelay: 5000
                        }
                    )
                });
        },
        deleteConvocatoriaAsignatura() {
            this.$swal({
                title: "Estás seguro que deseas eliminar?",
                text: "Esta acción es irreversible!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then((willDelete) => {
                    if (willDelete) {
                        this.ss.destroyConvocatoriaAsignatura(this.convocatoriaasignatura)
                            .then((result) => {
                                let response = result.data;
                                console.log(response);
                                this.$bvToast.toast(
                                    response.msg,
                                    {
                                        title: 'Correcto',
                                        variant: 'success',
                                        autoHideDelay: 5000
                                    }
                                )
                                this.$refs['view-convocatoriaasignatura'].hide();
                                this.$refs['datatable-convocatoriaasignatura'].reload();
                            })
                            .catch(error => {
                                console.log(error);
                            })
                    } else {
                        //swal("Your imaginary file is safe!");
                    }
                });
        },
        getConvocatoria() {
            this.ss.listConvocatoria().then(
                (result) => {
                    let response = result.data;
                    this.convocatorias = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        getAsignatura() {
            this.ss.listAsignatura().then(
                (result) => {
                    let response = result.data;
                    this.asignaturas = response.data;
                }
            ).catch(error => {
                console.log(error);
            });
        },
        draw() {
            window.$('.btn-datatable-ConvocatoriaAsignatura').on('click', (evt) => {
                const data = window.$(evt.target)[0].id;
                this.showConvocatoriaAsignatura(data);
            });
        }
    },
    components: {
        dataTable,
        Loader
    },
    mounted() {
        var persona = JSON.parse(localStorage.getItem('persona'));
        if (!persona) {
          this.$router.push('/Login');
        }

        this.getConvocatoria();
        this.getAsignatura();
    }
};
